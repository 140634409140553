import { isString } from 'typesafe-utils';

export default function make_styler(styleList: { [k: string]: any }) {
  const filteredList = Object.fromEntries(Object.entries(styleList).filter(([key, value]) => isString(value)));

  return (strings: TemplateStringsArray, ...expr: string[]): string => {
    return strings
      .reduce((combined, string, index) => combined + string + (expr[index] || ''), '') // Build template string
      .split(' ') // Split into styles
      .reduce((classes, style) => (classes += (filteredList[style] || style) + ' '), '') // Load classes from each style and concatenate into a string
      .replace(/(?:\r\n|\r|\n)/g, '')
      .replace(/  +/g, ' ')
      .trim();
  };
}
