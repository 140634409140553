import { tw } from '@/utils/tw';

const Icon = tw.theme({
  base: '',
  variants: {
    size: {
      small: `h-4.5 w-4.5`,
      medium: `h-6 w-6`,
      large: `h-9 w-9`,
      extraLarge: `h-12 w-12`,
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export default Icon;
