import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { SoMe, SoMeProps } from './SoMe';

export interface StandaloneSoMeProps extends StandaloneComponentProps {
  href?: LinkProps['href'];
  name?: SoMeProps['name'];
  content?: React.ReactNode;
  options?: Omit<SoMeProps, 'name'> & {
    $link?: LinkProps['options'];
  };
}

export const StandaloneSoMe: StandaloneComponent<StandaloneSoMeProps> = ({
  href,
  name,
  options,
  content,
  ...props
}) => {
  const { $link: linkProps, ...baseProps } = options || {};

  return (
    <Link
      href={href}
      target="_blank"
      options={linkProps}
      content={
        <SoMe name={name} {...baseProps} {...props}>
          {content}
        </SoMe>
      }
    />
  );
};
