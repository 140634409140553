import { useSetFirstLoginTime } from '@/hooks/useSetFirstLoginTime';
import { useClassName } from '@/styles/Edge';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { useFeature } from '@growthbook/growthbook-react';
import { ComponentContextFactory } from 'lib/contexts/ComponentContextFactory';
import Head from 'next/head';
import { RefObject, useMemo, useRef } from 'react';

export interface EdgeContextValue {
  ref: RefObject<HTMLElement>;
}

export const {
  Context: EdgeContext,
  Provider: EdgeProvider,
  useComponentContext: useEdge,
} = ComponentContextFactory<EdgeContextValue>();

export interface StandaloneEdgeProps extends ComponentProps<'section'> {
  favicon?: string;
}

export const StandaloneEdge: Component<StandaloneEdgeProps> = ({ children, favicon, ...props }) => {
  const ref = useRef<HTMLElement>(null);
  const className = useClassName('', props);

  useSetFirstLoginTime();
  useFeature('user-group');
  useFeature('sv-gb-test');

  const context = useMemo(() => ({ ref }), [ref]);

  return (
    <EdgeProvider value={context}>
      <Head>
        <link rel="icon" href={favicon ?? '/favicon.png'} />
      </Head>

      <section {...mergeProps({ className }, props)} ref={ref}>
        {children}
      </section>
    </EdgeProvider>
  );
};
