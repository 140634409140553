import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterSoMeHeadlineProps = ComponentProps<'header'>;

export const FooterSoMeHeadlineComponent: Component<FooterSoMeHeadlineProps> = ({
  children,
  colors,
  size,
  variant,
  ...props
}) => {
  const className = componentClassName('soMe_headline', { variant, colors, size });

  return <header {...mergeProps({ className }, props)}>{children}</header>;
};
