/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneBase as ResolvedStandaloneBase } from 'base/components/standalone/Base';

export const Base: typeof ResolvedStandaloneBase = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneBase {...props} />
        </ErrorBoundary>
    );
});

export type BaseProps = PropsFromComponent<typeof Base>;

/** @deprecated Use slot architecture instead */
export const BaseWith = (extras: DynamicStandaloneExtras): typeof Base => {
    return function Base(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Base');
        return <ResolvedStandaloneBase {...mergeProps({ options: { theme } }, props)} />;
    }
}