import { Component } from '@/types/component';
import { MobileAppEnvironment, MobileAppTrackingStatus } from '@/types/mobileApp';
import { createContext } from 'react';

export interface MobileAppState {
  appVersion?: string;
  buildVersion?: string;
  environment?: MobileAppEnvironment;
  isMobileApp?: boolean;
  trackingStatus?: MobileAppTrackingStatus;
}

const initialState: MobileAppState = {
  isMobileApp: false,
};

export const MobileAppStateContext = createContext<MobileAppState>(initialState);

export const MobileAppStateProvider: Component<MobileAppState> = ({ children, ...state }) => {
  const resolvedState: MobileAppState = state.isMobileApp ? state : initialState;

  return <MobileAppStateContext.Provider value={resolvedState}>{children}</MobileAppStateContext.Provider>;
};
