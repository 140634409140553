import { getBrandBy } from './getBrandBy';
import { getSite } from './getSite';

const site = getSite();

export const getCurrentBrand = () => {
  const brand = getBrandBy({ slug: site });

  if (!brand) {
    throw new Error(`Brand not found for site ${site}`);
  }

  return brand;
};
