import { getStackTrace } from '@/utils/getStackTrace';

type Logger = typeof logger;
type LoggerMethod = (...message: any[]) => void;
type LogSeverity = 'DEFAULT' | 'DEBUG' | 'INFO' | 'NOTICE' | 'WARNING' | 'ERROR' | 'CRITICAL' | 'ALERT' | 'EMERGENCY';

const withStyles = (background?: string, color?: string) => {
  return [
    `padding: 5px`,
    `border-radius: 99px`,
    background && `background-color: ${background}`,
    color && `color: ${color}`,
  ]
    .filter(Boolean)
    .join(';');
};

const withClientMessageParsing = (fragment: any) => {
  try {
    if (fragment instanceof Error) {
      return fragment.message;
    }

    if (typeof fragment === 'object') {
      return fragment;
    }

    return fragment.toString();
  } catch (error) {
    return fragment;
  }
};

const withServerMessageParsing = (fragment: any) => {
  try {
    if (typeof fragment === 'object') {
      if (fragment instanceof Error) {
        return fragment.message;
      }

      return JSON.stringify(fragment);
    }

    return fragment.toString();
  } catch (error) {
    return fragment;
  }
};

const isomorphic =
  (severity: LogSeverity, prefix: string, styles?: string[], stackTrace: boolean = true) =>
  (...message: any[]) => {
    const isClient = typeof window !== 'undefined';
    const isForcedClient = process.env.LOGGER_FORCE_CLIENT === 'true';

    const log: string[] =
      isClient || isForcedClient
        ? [
            `%c${prefix}`,
            withStyles(...(styles ?? [])),
            ...message.map(withClientMessageParsing),
            stackTrace ? '\n' + getStackTrace({ limit: 5 }) : '',
          ]
        : [
            JSON.stringify({
              severity,
              message: message.map(withServerMessageParsing).join(' '),
              'logging.googleapis.com/sourceLocation': {
                function: getStackTrace({ inline: true, limit: 3 }),
              },
            }),
          ];

    const logger: LoggerMethod = isClient ? console.debug : console.log;

    return logger(...log);
  };

const debug: LoggerMethod = isomorphic('DEBUG', '🪲  Debug', ['lightgray'], false);

const error: LoggerMethod = isomorphic('ERROR', '❗ Error!', ['firebrick', 'white']);

const info: LoggerMethod = isomorphic('INFO', '🗨️  Info', ['cornflowerblue', 'white'], false);

const log: LoggerMethod = isomorphic('DEFAULT', '🪵 Log', ['azure']);

const trace: LoggerMethod = isomorphic('DEBUG', '🐾 Trace', ['whitesmoke']);

const warn: LoggerMethod = isomorphic('WARNING', '⚠️  Warning!', ['orange'], false);

const $catch: LoggerMethod = isomorphic('CRITICAL', '❗❗❗  Critical!', ['firebrick', 'white']);

export const logger = Object.assign(log, {
  debug,
  error,
  info,
  log,
  trace,
  warn,
  catch: $catch,
});
