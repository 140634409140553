import { IconTheme } from '@/components/Icon/theme';
import { logger } from '@/utils/logger';
import { GenericSlot } from 'base/components/GenericSlot';
import { iconsDictionary } from './Icon.dictionary';

export const Icon = GenericSlot({
  theme: IconTheme,
  as: 'svg',
  render: ({ props }) => {
    const name = props.name as keyof typeof iconsDictionary;
    const Icon = iconsDictionary[name];

    if (!Icon) {
      logger.error(`Icon "${name}" not found`);
      return <></>;
    }

    return <Icon {...props} />;
  },
});
