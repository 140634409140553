import { NestedKeys, PathType } from '@/types/utils';
import { logger } from '@/utils/logger';
import brandConfig from 'modules/config/merge.config.json';
import baseConfig from '../../base/config.json';

type Config = typeof baseConfig;
type ConfigKeys = NestedKeys<Config>;

/**
 * A function that takes a dot separated key string, returning a configuration value
 * in either the base config or the site specific one.
 *
 * Ex: key: 'access.this.config.value'
 */

export default function getConfig<K extends ConfigKeys | ''>(key: K): PathType<Config, K> {
  if (key === '') return brandConfig as unknown as PathType<Config, K>;

  try {
    // We create a REAL copy of the config found as to not cause stupid reference errors
    return JSON.parse(JSON.stringify(key.split('.').reduce((o: any, i) => o?.[i], brandConfig)));
  } catch (error) {
    logger.error(`Failed to get config key: ${key}`, error);
    return undefined as unknown as PathType<Config, K>;
  }
}
