import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  base: `peer/icon absolute right-2 top-1/2 -translate-y-1/2 text-black transition-all group-data-[status=error]:text-error-600`,
  variants: {
    secondary: {
      true: `h-5 w-5 group-focus-within:bottom-2 group-focus-within:h-3.5 group-focus-within:w-3.5 group-focus-within:translate-y-0 group-data-[filled]:bottom-2 group-data-[filled]:h-3.5 group-data-[filled]:w-3.5 group-data-[filled]:translate-y-0`,
    },
  },
});

const TextField = tw.theme({
  slots: {
    base: `group flex w-full flex-col items-start gap-y-1`,
    label: `group-focus-within:text-primary-700 text-gray-700 group-data-[status=error]:!text-error-600`,
    input: `outline-primary-200 focus:bg-primary-50 focus:ring-primary-700 group-data-[status=error]:bg-error-50 w-full rounded bg-white p-2 outline outline-0 outline-offset-1 ring-1 ring-inset ring-gray-700 transition-all placeholder:text-gray-700 group-data-[status=error]:outline group-data-[status=error]:outline-4 group-data-[status=error]:outline-error-200 group-data-[status=error]:ring-2 group-data-[status=error]:ring-inset group-data-[status=error]:ring-error-600 group-data-[status=error]:placeholder:text-gray-700 hover:not-focus:bg-white hover:not-focus:outline-0 hover:not-focus:ring-1 hover:not-focus:ring-black focus:outline-4 focus:ring-2`,
    group: `relative flex w-full flex-col items-start gap-y-1`,
    caption: `text-body-xs text-gray-900 transition-colors group-data-[status=error]:text-error-600`,
  },
  variants: {
    variant: {
      primary: {
        base: ``,
        label: `text-body-xs transition-color relative`,
        input: `h-10 peer-[.absolute]/icon:pr-7`,
        caption: ``,
        group: ``,
      },
      secondary: {
        base: ``,
        label: `text-body-sm group-focus-within:text-body-2xs group-data-[filled]:text-body-2xs absolute left-2 top-1/2 z-1 -translate-y-1/2 transition-all group-focus-within:top-1 group-focus-within:translate-y-1.5 group-data-[filled]:top-1 group-data-[filled]:translate-y-1.5`,
        input: `h-14 pt-6 placeholder:opacity-0`,
        caption: ``,
        group: ``,
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default Object.assign(TextField, { Icon });
