import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface NavigationDrawerProps extends ComponentProps<'div'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const NavigationDrawerComponent: Component<NavigationDrawerProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('drawer_colors', colors);
  const sizeClassName = getPropStyles('drawer_size', size);
  const variantClassName = getPropStyles('drawer_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div id="navigationDrawer" className={componentClassName} {...props}>
      {children}
    </div>
  );
};
