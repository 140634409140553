import { isDev } from '@/utils/isDev';
import { isServer } from '@/utils/isServer';
import type { WhyDidYouRenderOptions } from '@welldone-software/why-did-you-render';
import React from 'react';

export const withComponentsRenderDebug = () => {
  const isEnabled = process.env.WHY_DID_YOU_RENDER_PLUGIN_ENABLED === 'true';

  if (!isEnabled || isServer() || !isDev) return;

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  const options: WhyDidYouRenderOptions = {
    trackAllPureComponents: true,
    trackExtraHooks: [],
    collapseGroups: true,
    include: [/./],
    exclude: [
      /^App$/,
      /^AppContainer$/,
      /^MyApp$/,
      /^Head$/,
      /^RouteAnnouncer$/,
      /^SideEffect$/,
      /^Portal$/,
      /^Unknown$/,
    ],
  };

  whyDidYouRender(React, options);
};
