import { Page4XXTheme } from '@/components/Page4XX/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: Page4XXTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'h1', slot: 'headline' });
const Description = $({ as: 'div', slot: 'description' });

export const Page4XX = Object.assign(Base, {
  Headline,
  Description,
});
