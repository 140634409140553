import { Link, LinkProps } from '@/components/Link';
import * as styles from '@/styles/Navigation';
import { componentTheme } from '@/styles/Navigation';
import { ClassNameProp, ElementKey, StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { GenericComponent } from 'base/components/GenericComponent';
import { useComponentsState } from 'lib/hooks/useComponentsState';
import { TrackingProps, withTrackingAttributes } from '@/utils/withTrackingAttributes';

export interface NavigationLinkProps extends LinkProps {
  as?: ElementKey;
  tracking?: TrackingProps;
  options?: LinkProps['options'] & {
    colors?: ClassNameProp<'bar' | 'drawer'>;
    size?: ClassNameProp<'bar' | 'controls' | 'drawer'>;
    variant?: ClassNameProp<'bar' | 'drawerPrimary' | 'drawerSecondary'>;
  };
}

export const NavigationLinkComponent: StandaloneComponent<NavigationLinkProps> = ({ as, tracking = {}, ...props }) => {
  const {
    Navigation: {
      $drawer: { hide },
    },
  } = useComponentsState();
  const trackingAttrs = withTrackingAttributes(tracking);

  if (as) {
    const { content, options, ...asProps } = props;
    const Component = GenericComponent({ as, styles });

    return (
      <Component {...{ theme: componentTheme('link'), ...options }} {...trackingAttrs} {...asProps}>
        {content}
      </Component>
    );
  }

  return (
    <Link
      {...trackingAttrs}
      {...mergeProps(
        {
          options: {
            colors: 'bar',
            size: 'bar',
            variant: 'bar',
            theme: componentTheme('link'),
            onClick: hide,
          },
        },
        props,
      )}
    />
  );
};
