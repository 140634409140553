import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import MabraLogo from '@/mabra/public/image/mabra-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';

const currentYear = new Date().getFullYear();

const copyright: StandaloneFooterProps['copyright'] = (
  <span>
    För prenumerationsärenden, ring{' '}
    <a
      className="underline"
      href="tel:0424443000"
      aria-label="Länk med telefonnummer för hjälp med prenumerationsärenden"
    >
      042 444 30 00
    </a>{' '}
    • Ansvarig utgivare: Jennie Sandberg © Copyright {currentYear} mabra.com
    <br />
    Måbra är en del av{' '}
    <a target="_blank" className="underline" href="https://www.aller.se" aria-label="Länk till Aller Medias hemsida">
      Aller Media
    </a>
    . Humlegårdsgatan 6, 114 46 Stockholm,{' '}
    <a className="underline" href="tel:086794600" aria-label="Länk med telefonnummer till Aller Media">
      08 679 46 00
    </a>
  </span>
);

const columns: StandaloneFooterProps['columns'] = (
  <>
    <Footer.Column>
      <Footer.List.Title>Om oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/om-oss" content="Om oss" aria-label="Länk till information om Måbra" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/halsoverktyg/nyhetsbrev"
            content="Nyhetsbrev"
            aria-label="Länk till att prenumera på Måbras nyhetsbrev"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/alla-amnen"
            content="Alla ämnen"
            aria-label="Länk till Alla ämnen-sidan där alla kategorier på Måbra listas i alfabetisk ordning"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/vara-skribenter"
            content="Våra skribenter"
            aria-label="Länk till information om skribenterna på Måbra"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/om-cookies" content="Om cookies" aria-label="Länk till information om cookies på Måbra" />
        </Footer.List.Item>
        <Footer.List.Item>
          <button
            type="button"
            onClick={showOneTrustConsent}
            aria-label="Knapp till att hantera inställnigar för cookies på Måbra"
          >
            Hantera preferenser
          </button>
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://www.aller.se/integritetspolicy/"
            target="_blank"
            content="Integritetspolicy"
            aria-label="Länk till information om Aller Medias integritetspolicy"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://www.aller.se/ai-policy/"
            target="_blank"
            content="Aller Medias AI-policy"
            aria-label="Länk till Aller Medias AI-policy"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/creative-studio/riktlinjer-for-kommersiellt-innehall-pa-mabracom/10293555"
            content="Kommersiell policy Måbra"
            aria-label="Länk till MåBras komersiella policy"
          />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>MåBra.com</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/halsa" content="Hälsa" aria-label="Länk till kategorin Hälsa" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/traning" content="Träning" aria-label="Länk till kategorin Träning" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/nyttig-mat" content="Nyttig mat" aria-label="Länk till kategorin Nyttig mat" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/senaste-nytt" content="Senaste nytt" aria-label="Länk till kategorin Senaste nytt" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/medicin" content="Medicin" aria-label="Länk till kategorin Medicin" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/feelgood" content="Feelgood" aria-label="Länk till kategorin Feelgood" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/bloggar" content="Bloggar" aria-label="Länk till Måbras bloggare" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Kontakta oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link
            href="https://prenumerera.se/tidningar/mabra/"
            content="Bli prenumerant"
            aria-label="Länk till att prenumerera på Måbra"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/kontakt" content="Kontakta oss här" aria-label="Länk till hur du kan kontakta Måbra" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>
  </>
);

const description: StandaloneFooterProps['description'] = (
  <>
    MåBra är Sveriges största hälsomagasin. Här hittar du senaste hälsonyheterna, mängder av kost- och träningstips
    och annan inspirerande läsning. Vi guidar dig till ett hållbart, hälsosamt och härligt liv - där hela du ska må bra.
  </>
);

const editors: StandaloneFooterProps['editors'] = 'Chefredaktör och ansvarig utgivare: Jennie Sandberg';

const logo: StandaloneFooterProps['logo'] = <MabraLogo />;

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>

    <Footer.SoMe
      href="https://www.facebook.com/tidningenmabra/"
      name="facebook"
      aria-label="Länk till Måbras Facebook-sida"
    />
    <Footer.SoMe
      href="https://www.instagram.com/mabra_com/"
      name="instagram"
      aria-label="Länk till Måbras Instagram-sida"
    />
    <Footer.SoMe
      href="https://www.pinterest.com/mabra_com/"
      name="pinterest"
      aria-label="Länk till Måbras Pinterest-sida"
    />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAqBwgKMJiyowsw27y7Aw?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        className: '!pt-0.5 !pb-0.75',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Footer.SoMe.Group>
);

export const MabraStandaloneFooter: typeof StandaloneFooter = () => {
  return <StandaloneFooter {...{ copyright, columns, description, editors, logo, soMe }} />;
};
