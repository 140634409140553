import { Footer, FooterProps } from '@/components/Footer';
import { Main, MainProps } from '@/components/Main';
import { Navigation, NavigationProps } from '@/components/Navigation';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';

export interface StandaloneBaseProps extends StandaloneComponentProps {
  content?: ReactNode;
  options?: {
    $navigation?: NavigationProps;
    $main?: MainProps;
    $footer?: FooterProps;
  };
}

export const StandaloneBase: StandaloneComponent<StandaloneBaseProps> = ({ content, options }) => {
  const { $navigation, $main, $footer } = { ...options };

  return (
    <>
      <Navigation {...$navigation} />
      <Main content={content} {...$main} />
      <Footer {...$footer} />
    </>
  );
};
