type PropertyChangeListener = (value: any) => void;

const listeners: Record<string, PropertyChangeListener[]> = {};

const addListener = (property: string, listener: PropertyChangeListener) => {
  if (!listeners[property]) {
    listeners[property] = [];
  }
  listeners[property].push(listener);
};

const removeListener = (property: string, listener: PropertyChangeListener) => {
  listeners[property] = listeners[property].filter((l) => {
    return listener !== l;
  });
};

const notifyListeners = (property: string, value: any) => {
  const propertyListeners = listeners[property];
  if (propertyListeners) {
    propertyListeners.forEach((listener) => {
      listener(value);
    });
  }
};

const monitorProperty = <T extends object>(target: T, property: keyof T) => {
  let oldValue = target[property];

  Object.defineProperty(target, property, {
    get: () => oldValue,
    set: (value) => {
      oldValue = value;
      notifyListeners(property.toString(), value);
    },
    configurable: true,
  });
};

export { listeners, addListener, removeListener, notifyListeners, monitorProperty };
