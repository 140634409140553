import { tw } from '@/utils/tw';

const Hamburger = tw.theme({
  slots: {
    base: `group/hamburger relative block h-6 w-6 cursor-pointer bg-transparent`,
    segment: `bg-primary-700 absolute top-1/2 -mt-0.25 h-0.5 w-full transform rounded-full border-0 transition-all duration-300 ease-in-out`,
    toggle: `peer absolute left-0 top-0 h-full w-full cursor-pointer appearance-none`,
  },
  variants: {
    animation: {
      primary: {
        segment: `last-of-type:translate-y-1.5 peer-checked:translate-y-0 peer-checked:last-of-type:-rotate-45 first-of-type:-translate-y-1.5 peer-checked:first-of-type:rotate-45 peer-checked:second-of-type:scale-x-0 third-of-type:hidden`,
      },
      secondary: {
        segment: `last-of-type:translate-y-2 peer-checked:last-of-type:translate-y-5 peer-checked:last-of-type:scale-x-0 first-of-type:-translate-y-2 peer-checked:first-of-type:-translate-y-5 peer-checked:first-of-type:scale-x-0 peer-checked:second-of-type:rotate-45 peer-checked:third-of-type:-rotate-45`,
      },
    },
  },
  defaultVariants: {
    animation: 'primary',
  },
});

export default Hamburger;
