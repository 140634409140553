import { getCurrentBrand } from '@/utils/getCurrentBrand';
import Script from 'next/script';
import React from 'react';

interface GtmProps {
  gtmId: string;
}

const Gtm: React.FC<GtmProps> = ({ gtmId }) => {
  const { host } = getCurrentBrand();
  const gtmScriptUrl = `https://analytics.${host}/gtm.js?id=${gtmId}`;

  const initGtm = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  };

  return <Script id="gtm-tracking" src={gtmScriptUrl} onLoad={initGtm} async />;
};

export default Gtm;
