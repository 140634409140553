import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterSitesItemProps = ComponentProps<'li'>;

export const FooterSitesItemComponent: Component<FooterSitesItemProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const className = componentClassName('sites_item', { variant, size, colors });

  return <li {...mergeProps({ className }, props)}>{children}</li>;
};
