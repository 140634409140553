import { useCallback, useMemo, useState } from 'react';

export interface ComponentStateController {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

export interface ComponentStateControllerProps {
  defaultVisible?: boolean;
}

export type ComponentStateControllerHook = (props?: ComponentStateControllerProps) => ComponentStateController;

export const useComponentStateController: ComponentStateControllerHook = ({ defaultVisible } = {}) => {
  const [isVisible, setVisible] = useState(defaultVisible ?? false);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);
  const toggle = useCallback(() => setVisible((isVisible) => !isVisible), []);

  return useMemo(
    () => ({
      isVisible,
      show,
      hide,
      toggle,
    }),
    [isVisible, show, hide, toggle]
  );
};

export const initialComponentStateController: ComponentStateController = {
  isVisible: false,
  show: () => {},
  hide: () => {},
  toggle: () => {},
};
