import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import Head from 'next/head';
import { Page4XX } from './Page4XX';
import { config, Page4XXType } from './config';

export interface StandalonePage4XXProps extends StandaloneComponentProps<typeof Page4XX> {
  status: Page4XXType;
}

export const StandalonePage4XX: StandaloneComponent<StandalonePage4XXProps> = ({ status, ...props }) => {
  const { title, headline, description } = config[status] ?? config[404];

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Page4XX {...props}>
        <Page4XX.Headline>{headline}</Page4XX.Headline>
        <Page4XX.Description>{description}</Page4XX.Description>
      </Page4XX>
    </>
  );
};
