import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface NavigationSoMeGroupProps extends ComponentProps<'aside'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const NavigationSoMeGroupComponent: Component<NavigationSoMeGroupProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('soMe_group_colors', colors);
  const sizeClassName = getPropStyles('soMe_group_size', size);
  const variantClassName = getPropStyles('soMe_group_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <aside className={componentClassName} {...props}>
      {children}
    </aside>
  );
};
