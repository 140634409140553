import { isServer } from '@/utils/isServer';
import { isUndefined } from 'typesafe-utils';

const nativeCookieProxy = '__cookie__';

declare global {
  interface Document {
    [nativeCookieProxy]: Document['cookie'];
  }
}

const getCookieChangedEvent = (cookie: string) => new CustomEvent('cookieChanged', { detail: cookie });

export const withCookiesInterceptor = () => {
  if (isServer()) return;

  const nativeCookieDescriptor = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');

  if (isUndefined(nativeCookieDescriptor)) return;

  if (isUndefined(document[nativeCookieProxy])) {
    Object.defineProperty(Document.prototype, nativeCookieProxy, nativeCookieDescriptor);
  }

  Object.defineProperty(Document.prototype, 'cookie', {
    configurable: true,
    enumerable: true,
    get: () => document[nativeCookieProxy],
    set: (value) => {
      const currentCookie = document[nativeCookieProxy];
      document[nativeCookieProxy] = value;
      const updatedCookie = document[nativeCookieProxy];

      if (currentCookie === updatedCookie) return;

      const cookieChangedEvent = getCookieChangedEvent(updatedCookie);
      document.dispatchEvent(cookieChangedEvent);
    },
  });
};
