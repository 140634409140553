/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandalonePage4XX as ResolvedStandalonePage4XX } from 'base/components/standalone/Page4XX';

export const Page4XX: typeof ResolvedStandalonePage4XX = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandalonePage4XX {...props} />
        </ErrorBoundary>
    );
});

export type Page4XXProps = PropsFromComponent<typeof Page4XX>;

/** @deprecated Use slot architecture instead */
export const Page4XXWith = (extras: DynamicStandaloneExtras): typeof Page4XX => {
    return function Page4XX(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Page4XX');
        return <ResolvedStandalonePage4XX {...mergeProps({ options: { theme } }, props)} />;
    }
}