import { isUndefined } from 'typesafe-utils';
import { addListener, monitorProperty, removeListener } from './common';

export const watchOnce = <T extends {}, ReturnValue, FallbackValue>(
  target: T,
  property: keyof T,
  options?: { timeout?: number; fallbackValue?: FallbackValue },
): Promise<ReturnValue | FallbackValue> => {
  const opts = {
    timeout: 5000,
    ...options,
  };

  const hasFallbackValue = Object.hasOwn(opts, 'fallbackValue');

  return new Promise((resolve, reject) => {
    const propertyDescriptor = Object.getOwnPropertyDescriptor(target, property);
    const hasPropertyWithValue = Boolean(propertyDescriptor && Object.hasOwn(propertyDescriptor, 'value'));

    if (hasPropertyWithValue) {
      resolve(target[property] as ReturnValue);
    } else {
      const timeoutId = setTimeout(() => {
        const currentValue = target[property];
        const valIsUndefined = isUndefined(currentValue);

        if (valIsUndefined && !hasFallbackValue) {
          reject(
            new Error(`Timeout: Property '${property.toString()}' did not get a value within the specified timeout.`),
          );
        } else if (valIsUndefined && hasFallbackValue) {
          resolve(opts.fallbackValue as any);
        }

        resolve(currentValue as ReturnValue);
      }, opts.timeout);

      const listener = (value: any) => {
        clearTimeout(timeoutId);
        removeListener(property.toString(), listener);
        resolve(value);
      };

      addListener(property.toString(), listener);
      monitorProperty(target, property);
    }
  });
};
