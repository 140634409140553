/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneAccordion as ResolvedStandaloneAccordion } from 'base/components/standalone/Accordion';

export const Accordion: typeof ResolvedStandaloneAccordion = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneAccordion {...props} />
        </ErrorBoundary>
    );
});

export type AccordionProps = PropsFromComponent<typeof Accordion>;

/** @deprecated Use slot architecture instead */
export const AccordionWith = (extras: DynamicStandaloneExtras): typeof Accordion => {
    return function Accordion(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Accordion');
        return <ResolvedStandaloneAccordion {...mergeProps({ options: { theme } }, props)} />;
    }
}