import { SoMe, SoMeProps } from '@/components/SoMe';
import { componentTheme } from '@/styles/Footer';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterSoMeProps = SoMeProps;

export const FooterSoMeComponent: StandaloneComponent<FooterSoMeProps> = (props) => {
  return (
    <SoMe
      {...mergeProps(
        {
          options: {
            theme: componentTheme('soMe'),
          },
        },
        props,
      )}
    />
  );
};
