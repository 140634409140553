export const withInstanceofProp = <Component extends React.FunctionComponent<any>>(
  component: Component,
  value: symbol,
) => Object.assign(component, { $$instanceof: value }) as Component;

export const InstanceofLabradorComponent = Symbol.for('component.labrador');
export const withLabradorInstanceof = <Component extends React.FunctionComponent<any>>(component: Component) =>
  withInstanceofProp(component, InstanceofLabradorComponent) as Component;

export const InstanceofStandaloneComponent = Symbol.for('component.standalone');
export const withStandaloneInstanceof = <Component extends React.FunctionComponent<any>>(component: Component) =>
  withInstanceofProp(component, InstanceofStandaloneComponent) as Component;

export const InstanceofSlotComponent = Symbol.for('component.slot');
export const withSlotInstanceof = <Component extends React.FunctionComponent<any>>(component: Component) =>
  withInstanceofProp(component, InstanceofSlotComponent) as Component;
