import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface NavigationMenuProps extends ComponentProps<'nav'> {
  colors?: ClassNameProp<'primary' | 'secondary' | 'none'>;
  size?: ClassNameProp<'bar' | 'controls' | 'drawer'>;
  variant?: ClassNameProp<'bar' | 'controls' | 'drawer'>;
}

export const NavigationMenuComponent: Component<NavigationMenuProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('menu_colors', colors, 'none');
  const sizeClassName = getPropStyles('menu_size', size, 'bar');
  const variantClassName = getPropStyles('menu_variant', variant, 'bar');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <nav className={componentClassName} {...props}>
      {children}
    </nav>
  );
};
