import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  defaultVariants: {
    size: 'medium',
  },
});

const Accordion = tw.theme({
  slots: {
    base: `group/accordion`,
    content: `hidden group-[[data-open]>]/accordion:block md:block`,
    group: `flex items-center justify-between`,
    toggle: `cursor-pointer pr-4 transition-transform group-[[data-open]>:first-child]/accordion:[transform:rotateX(180deg)] md:hidden`,
    headline: ``,
  },
});

export default Object.assign(Accordion, { Icon });
