import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterListTitleProps = ComponentProps<'h4'>;

export const FooterListTitleComponent: Component<FooterListTitleProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const className = componentClassName('list_title', { variant, size, colors });

  return <h4 {...mergeProps({ className }, props)}>{children}</h4>;
};
