/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "bg-gradient-to-b from-cherry-blossom-300 to-[#E1727D] hover:bg-cherry-blossom-300 focus:bg-gradient-to-b focus:from-cherry-blossom-300 focus:to-cherry-blossom-200 focus:ring-cannon-pink-400 active:bg-gradient-to-b active:from-cherry-blossom-300 active:to-cherry-blossom-200 after:shadow-black/10 after:bg-black/10 border-transparent hover:to-cherry-blossom-300",
  "colors_secondary": "bg-white border-cherry-blossom-400 text-cherry-blossom-500 hover:bg-cherry-blossom-300 hover:text-black hover:border-cherry-blossom-300 focus:bg-gradient-to-b focus:from-cherry-blossom-300 focus:to-cherry-blossom-200 focus:ring-cannon-pink-400 focus:text-black focus:border-cannon-pink-400 focus:hover:border-cannon-pink-400 focus:active:border-transparent focus:active:border-t-cherry-blossom-300 focus:active:border-b-cherry-blossom-200 active:bg-gradient-to-b active:from-cherry-blossom-300 active:to-cherry-blossom-200 active:text-black after:shadow-none",
  "size_small": "px-5 pt-1 pb-1.5 text-headline-xs gap-1.5 after:hidden",
  "size_medium": "px-6 pt-2 pb-2.5 text-headline-xs gap-2.5 after:h-0.25 after:w-full",
  "size_large": "px-6 pt-3 pb-3.5 text-headline-xs gap-2.5 after:h-0.25 after:w-full",
  "variant_default": "relative rounded-full ring-0 focus:ring-4 focus-visible:outline-none active:ring-0 transition-all duration-200 ease-in-out after:shadow-[0px_0px_2px_2px] after:rounded-[50%] after:-z-50 after:absolute after:-bottom-0.5 after:left-0 border-2 flex-row inline-flex items-center justify-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;