import { isServer } from '@/utils/isServer';

export const initAllerUserConsentQueue = () => {
  if (isServer()) return;

  const allerUserConsentDescriptor = Object.getOwnPropertyDescriptor(window?.allerUserConsent || {}, 'queue');

  if (!allerUserConsentDescriptor || allerUserConsentDescriptor.writable) {
    window.allerUserConsent = window.allerUserConsent || {};
    window.allerUserConsent.queue = window.allerUserConsent.queue || [];
  }
};
