import { tw } from '@/utils/tw';

const Page4XX = tw.theme({
  slots: {
    base: `my-9 flex min-w-0 flex-col items-center gap-6 text-center`,
    headline: `break-words text-black text-headline-lg`,
    description: `break-words text-black text-body [&_a]:text-gray-600`,
  },
});

export default Page4XX;
