import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterLogoProps = ComponentProps<'div'>;

export const FooterLogoComponent: Component<FooterLogoProps> = ({ children, colors, size, variant, ...props }) => {
  const className = componentClassName('logo', { variant, size, colors });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
