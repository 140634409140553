import { useStable } from '@/hooks/useStable';
import { ClassNameProps, LegacyThemeProp } from '@/types/component';
import { AnyString } from '@/types/utils';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { mergeOptions } from '@/utils/merge';
import { mergeDefined } from '@/utils/mergeDefined';
import { camelCase, pick } from 'lodash-es';
import { createContext, useContext } from 'react';
import { ClassNameValue, twJoin } from 'tailwind-merge';

export type OptionsProp = { options?: ClassNameOptions<any> };

export type ClassNameContext = ClassNameProps & LegacyThemeProp & OptionsProp;

export type ClassNameFallback = { [Key in keyof ClassNameProps]?: keyof ClassNameProps };

export type ClassNameOptions<C extends ClassNameContext> = {
  defaults?: Omit<C, keyof LegacyThemeProp | keyof OptionsProp>;
  fallbacks?: ClassNameFallback;
  className?: ClassNameValue;
};

export type ClassNameProvider = React.FunctionComponent<
  { value?: Omit<ClassNameContext, keyof OptionsProp> } & OptionsProp & React.PropsWithChildren
>;

export type ExtractedThemes<Styles extends Record<string, any>> = {
  [Key in keyof Styles as Styles[Key] extends Record<string, any> ? Key : never]: Styles[Key];
};

export type UseClassName = <C extends ClassNameContext>(
  key?: string,
  props?: C,
  options?: ClassNameOptions<C>,
) => string;

export type UseTheme<Styles extends Record<string, any>> = (
  key?: keyof ExtractedThemes<Styles> | AnyString,
) => Record<string, any>;

const pickProps = <T extends object>(props: T) =>
  pick(props, ['animation', 'colors', 'size', 'variant', 'theme'] as const);

const withFallbacks = (props: ClassNameContext, fallback?: ClassNameFallback) => {
  const propsWithFallback = Object.fromEntries(
    Object.entries(fallback ?? {}).map(([propKey, fallbackKey]) => {
      const propValue = props?.[propKey as keyof ClassNameProps];
      const fallbackValue = props?.[fallbackKey as keyof ClassNameProps];

      return [propKey, propValue ?? fallbackValue];
    }),
  );

  return { ...props, ...propsWithFallback };
};

export const ComponentClassNameFactory = <Styles extends Record<string, any>>(
  componentClassName: ReturnType<typeof getComponentStylesFactory>,
  styles?: Styles,
) => {
  const ClassNameContext = createContext({} as ClassNameContext);

  const ClassNameProvider: ClassNameProvider = ({ children, value, options }) => {
    const pickedValue = pickProps(value ?? {});
    const stableValue = useStable(pickedValue, { options });

    return <ClassNameContext.Provider value={stableValue}>{children}</ClassNameContext.Provider>;
  };

  const useClassName: UseClassName = (key, props, options) => {
    const { theme: contextTheme, options: contextOptions, ...contextProps } = useContext(ClassNameContext);
    const { theme: componentTheme, ...componentProps } = props ?? {};
    const { defaults, fallbacks, className } = mergeOptions(contextOptions, options);

    const resolvedProps = withFallbacks(mergeDefined(contextProps, componentProps), fallbacks);
    const resolvedTheme = mergeDefined(contextTheme, componentTheme);
    const resolvedOptions = { defaults, theme: resolvedTheme };

    return twJoin(componentClassName(key ?? '', pickProps(resolvedProps), resolvedOptions), className);
  };

  const useTheme: UseTheme<Styles> = (key = '') => {
    const lowerCaseKey = (key as string).toLowerCase();
    const camelCaseKey = camelCase(key as string);
    const { theme } = useContext(ClassNameContext);
    const contextTheme = theme?.[key as string] || theme?.[lowerCaseKey] || theme?.[camelCaseKey];
    const componentTheme = styles?.[key] || styles?.[lowerCaseKey] || styles?.[camelCaseKey];
    const resolvedTheme = mergeDefined(componentTheme, contextTheme);
    const stableTheme = useStable(resolvedTheme);

    return stableTheme;
  };

  return { ClassNameContext, ClassNameProvider, useClassName, useTheme };
};
