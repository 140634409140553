import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useAppState } from 'lib/hooks/useAppState';
import { ReactNode } from 'react';
import { Main } from './Main';

export interface StandaloneMainProps extends StandaloneComponentProps<typeof Main> {
  content?: ReactNode;
}

export const StandaloneMain: StandaloneComponent<StandaloneMainProps> = ({ content, options, ...props }) => {
  const [{ publishedUrl }] = useAppState();

  return (
    <Main data-published-url={publishedUrl} {...options} {...props}>
      {content}
    </Main>
  );
};
