import { ComponentProps, StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useMemo } from 'react';
import { Hamburger } from './Hamburger';

export interface StandaloneHamburgerProps extends StandaloneComponentProps<typeof Hamburger> {
  content?: React.ReactNode;
  isOpen?: boolean;
  onChange?: ComponentProps<'input'>['onChange'];
  segments?: number;
}

export const StandaloneHamburger: StandaloneComponent<StandaloneHamburgerProps> = ({
  content,
  isOpen,
  onChange,
  segments,
  ...props
}) => {
  const defaultContent = useMemo(
    () => Array.from({ length: segments ?? 4 }).map((_, index) => <Hamburger.Segment key={index} />),
    [segments],
  );

  return (
    <Hamburger data-open={isOpen ? '' : undefined} {...props}>
      <Hamburger.Toggle
        aria-label="Knapp för att öppna meny med länkar"
        type="checkbox"
        onChange={onChange}
        checked={isOpen}
      />
      {content ?? defaultContent}
    </Hamburger>
  );
};
