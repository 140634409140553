import { IconProps } from '@/components/Icon';
import { ComponentProps, StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { ChangeEventHandler, ReactNode, useId, useState } from 'react';
import { TextField } from './TextField';

export interface StandaloneTextFieldProps extends StandaloneComponentProps<typeof TextField> {
  caption?: ReactNode;
  icon?: IconProps['name'];
  label?: ReactNode;
  placeholder?: ComponentProps<'input'>['placeholder'];
  status?: 'default' | 'error';
  type?: ComponentProps<'input'>['type'];
  // TODO: remove `name` once all components that use StandaloneTextField (fe: NewsletterSignupInputFieldComponent) are migrated to new way of writing components
  name?: ComponentProps<'input'>['name'];
}

export const StandaloneTextField: StandaloneComponent<StandaloneTextFieldProps> = ({
  caption,
  icon,
  label,
  name,
  placeholder,
  status,
  type,
  ...props
}) => {
  const [filled, setFilled] = useState<'' | undefined>(undefined);

  const id = useId();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilled(event.target.value ? '' : undefined);
    props.options?.$input?.onChange?.(event);
  };

  return (
    <TextField data-filled={filled} data-status={status} {...props}>
      <TextField.Group>
        {label && <TextField.Label htmlFor={id}>{label}</TextField.Label>}
        <TextField.Group>
          {icon && (
            <TextField.Icon
              name={icon}
              options={mergeOptions(
                {
                  secondary: props.options?.variant === 'secondary',
                },
                props?.options?.$icon,
              )}
            />
          )}
          <TextField.Input {...{ id, name, placeholder, type }} onChange={onChange} />
        </TextField.Group>
      </TextField.Group>
      {caption && <TextField.Caption>{caption}</TextField.Caption>}
    </TextField>
  );
};
