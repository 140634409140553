import type { ReactNode } from 'react';
import { isString } from 'typesafe-utils';

export const withSafeInnerHTML = (__html?: ReactNode) => {
  return __html && isString(__html)
    ? {
        dangerouslySetInnerHTML: { __html },
      }
    : {};
};
