import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { getCookie } from 'cookies-next';
import jwt from 'jsonwebtoken';
import { NextApiRequest } from 'next';

type Context = {
  cookies?: { [key: string]: string } | Partial<{ [key: string]: string }>;
  query?: { [key: string]: string | string[] | undefined };
};

export type AlltIdJwtToken = {
  data: {
    id: string;
    cc: string;
    open: boolean;
    user: {
      access: string | null;
      tags: string[];
    };
  };
};

export interface GetMyDataProps {
  articleId?: string;
  req?: NextApiRequest;
}

const myDataFactory = (endpoint: string) => (props?: GetMyDataProps) => {
  const token = getToken({ cookies: props?.req?.cookies });
  const userId = getUserId({ cookies: props?.req?.cookies });
  const articleId = props?.articleId;

  if (!userId || !endpoint) {
    return;
  }

  const path = [userId, endpoint, articleId].filter(Boolean).join('/');
  const url = getUrl(`/mydata/${path}`)?.href;

  if (!url || !token) {
    return;
  }

  return http.get(url, {
    headers: {
      'X-API-Token': token,
    },
  });
};

export const getSavedArticlesIds = myDataFactory('getSavedArticleIds');

export const saveArticle = myDataFactory('saveArticle');

export const removeSavedArticle = myDataFactory('removeSavedArticle');

export const setFirstLoginTime = myDataFactory('setFirstLoginTime');

const getToken = (context?: Context) =>
  (context?.cookies?.login_token as string) || (context?.query?.code as string) || getCookie('login_token')?.toString();

const getUserId = (context?: Context) => {
  const token = getToken(context);

  const payload = (token ? jwt.decode(token) : {}) as AlltIdJwtToken;

  return payload?.data?.cc as string | undefined;
};

export const getUserTags = (context?: Context): string[] => {
  const token = getToken(context);
  const payload = (token ? jwt.decode(token) : {}) as AlltIdJwtToken;

  return payload?.data?.user?.tags || [];
};
