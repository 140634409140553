export interface GetStackTraceOptions {
  verbose?: boolean;
  inline?: boolean;
  limit?: number;
  whitelist?: string[];
  blacklist?: string[];
}

const includes = (source: string) => (frag: string) => source.includes(frag);
const excludes = (source: string) => (frag: string) => !source.includes(frag);

export const getStackTrace = (options?: GetStackTraceOptions) => {
  const { verbose, inline, limit, whitelist, blacklist } = options || {};
  const { stack } = new Error();
  const trace = stack?.slice(6);

  return trace
    ?.split('\n')
    .reduce<{ break: boolean; stack: string[] }>(
      (result, current) => {
        const isLimitReached = !verbose && result.stack.length >= (limit ?? 1);

        if (current.includes('/node_modules/') || isLimitReached) {
          result.break = true;
        }

        const ignores = [
          'at eval',
          'at async eval',
          'at Promise.all',
          'at async Promise.all',
          'at logger',
          'at getStackTrace',
          'at axios',
          'at Function.debug',
          'at Function.error',
          'at Function.info',
          'at Function.log',
          'at Function.trace',
          'at Function.warn',
          'at Function.catch',
          'at Function.eval',
          'at onRequestFulfilled',
          'at onRequestRejected',
          'at onResponseFulfilled',
          'at onResponseRejected',
          'node:internal',
        ];
        const isIgnored = ignores.some(includes(current));

        if (!verbose && (result.break || isIgnored)) {
          return result;
        }

        const areIncludesCovered = whitelist?.every(includes(current)) ?? true;
        const areExcludesCovered = blacklist?.every(excludes(current)) ?? true;
        const trace = current?.replace('webpack-internal:///', '').trim();

        if (!isLimitReached && areIncludesCovered && areExcludesCovered) {
          result.stack.push(inline ? trace : `\t${trace}`);
        }

        return result;
      },
      { break: false, stack: [] },
    )
    .stack.join(inline ? ' ' : '\n');
};
