import { useMemo } from 'react';
import { isClient } from '@/utils/isClient';
import { isConsentDisabled } from '@/utils/isConsentDisabled';

type ConsentCallback = (response: string[] | undefined) => void;
type ConsentCheckFunction = (callback: ConsentCallback) => Promise<void>;

export enum UserConsentType {
  Essential = 'Essential',
  Performance = 'Performance',
  Functional = 'Functional',
  Targeting = 'Targeting',
  SocialMedia = 'SocialMedia',
}

export const checkUserConsent = (type: UserConsentType, callback: ConsentCallback) => {
  if (!isClient() || isConsentDisabled) return;

  const ConsentCheckFunction = window?.userConsent?.[`is${type}Approved`] as ConsentCheckFunction;
  if (ConsentCheckFunction) {
    ConsentCheckFunction(callback);
  }
};

const useConsentCheck = () => {
  return useMemo(() => {
    return { isConsentGranted: checkUserConsent };
  }, []);
};

export default useConsentCheck;
