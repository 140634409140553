import { Base } from '@/components/Base';
import { Consent } from '@/components/Consent';
import { Page4XX } from '@/components/Page4XX';
import { Tracking } from '@/components/Tracking';
import { ReactNode } from 'react';

export function with410(component: ReactNode, pageProps: any) {
  if (pageProps.isGone) {
    return (
      <Base
        content={
          <>
            <Consent />
            <Tracking />
            <Page4XX status={410} />
          </>
        }
      />
    );
  }
  return component;
}
