import { Component } from '@/types/component';
import { createContext } from 'react';
import {
  ComponentStateController,
  initialComponentStateController,
  useComponentStateController,
} from '../hooks/useComponentStateController';

export interface ComponentsState {
  Navigation: ComponentStateController & {
    $drawer: ComponentStateController;
    $search: ComponentStateController;
  };
}

const initialState: ComponentsState = {
  Navigation: {
    ...initialComponentStateController,
    $drawer: initialComponentStateController,
    $search: initialComponentStateController,
  },
} as ComponentsState;

export const ComponentsStateContext = createContext<ComponentsState>(initialState);

export const ComponentsStateProvider: Component = ({ children }) => {
  const state: ComponentsState = {
    Navigation: Object.assign(useComponentStateController({ defaultVisible: true }), {
      $drawer: useComponentStateController(),
      $search: useComponentStateController(),
    }),
  };

  return <ComponentsStateContext.Provider value={state}>{children}</ComponentsStateContext.Provider>;
};
