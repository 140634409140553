import { logger } from '@/utils/logger';
import { Component, ErrorInfo, ReactNode } from 'react';
import { TbFaceIdError } from 'react-icons/tb';

export const ErrorNode = (
  <div className="flex flex-row flex-wrap items-center justify-center gap-2 p-4 text-gray-600">
    <TbFaceIdError className="h-8 w-8" />
    Tyvärr, det uppstod ett fel.
  </div>
);

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.debug('Uncaught error:', { error, errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      return ErrorNode;
    }

    return this.props.children;
  }
}
