import { isClient } from '@/utils/isClient';
import { logger } from '@/utils/logger';

export const showOneTrustConsent = () => {
  if (isClient() && window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay();
  } else {
    logger.info('window.OneTrust is not available');
  }
};
