import { useLocalStorage } from 'react-use';
import { useCallback, useMemo } from 'react';
import { nanoid } from 'nanoid';

type AnonymousId = string;

const ANONYMOUS_ID_KEY = 'anonymousId';

const useAnonymousId = () => {
  const [anonymousId, setAnonymousId] = useLocalStorage<AnonymousId>(ANONYMOUS_ID_KEY, undefined, { raw: true });

  const getAnonymousId = useCallback<() => AnonymousId>(() => {
    if (anonymousId) return anonymousId;

    const id = nanoid();
    setAnonymousId(id);
    return id;
  }, [anonymousId, setAnonymousId]);

  return useMemo(() => ({ getAnonymousId }), [getAnonymousId]);
};

export default useAnonymousId;
