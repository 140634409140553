import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { StandaloneOptionalLinkProps } from '../OptionalLink';
import { Button, ButtonProps } from './Button';
import { ButtonLink } from './ButtonLink';

export interface StandaloneButtonProps extends StandaloneComponentProps {
  content?: ReactNode;
  link?: StandaloneOptionalLinkProps;
  options?: ButtonProps;
}

export const StandaloneButton: StandaloneComponent<StandaloneButtonProps> = ({ content, link, options, ...props }) => {
  if (link) {
    return (
      <ButtonLink link={link} {...props} {...options}>
        {content}
      </ButtonLink>
    );
  }

  return (
    <Button {...options} {...props}>
      {content}
    </Button>
  );
};
