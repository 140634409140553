import { tw } from '@/utils/tw';
import BaseHamburgerTheme from 'base/components/Hamburger/theme';

const Hamburger = tw.theme({
  extend: BaseHamburgerTheme,
  slots: {
    base: `h-9 w-7.5`,
    segment: `h-1 bg-black first-of-type:w-1/2 peer-checked:first-of-type:w-full`,
  },
  variants: {
    animation: {
      primary: {
        segment: `last-of-type:translate-y-2.5 first-of-type:-translate-y-2.5`,
      },
    },
  },
});

export default Hamburger;
