import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Icon } from './Icon';
import { iconsDictionary } from './Icon.dictionary';

export interface StandaloneIconProps extends StandaloneComponentProps<typeof Icon> {
  name?: keyof typeof iconsDictionary;
}

export const StandaloneIcon: StandaloneComponent<StandaloneIconProps> = (props) => {
  if (!props.name) {
    return null;
  }

  return <Icon {...props} />;
};
