import { GrowthBook } from '@growthbook/growthbook-react';

export const initGrowthBook = async (growthBook?: GrowthBook<never>) => {
  if (!growthBook) {
    return;
  }

  try {
    await growthBook.init({ timeout: 5000 });
  } catch {
    // ignore
  }
};
