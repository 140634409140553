import brands from 'lib/brands';

export type Brand = {
  id: number;
  name: string;
  slug: string;
  host: string;
  deprecated?: boolean;
};

export const getBrandBy = (input: Partial<Brand>): Brand | null => {
  const brand = (brands as Brand[]).find((brand) => {
    return Object.entries(input).every(([key, value]) => {
      return brand[key as keyof Brand] === value;
    });
  });

  return brand ? { ...brand } : null;
};
