import { setFirstLoginTime } from 'lib/alltIdMyData';
import getConfig from 'modules/config';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import useUserAuth from './useUserAuth';

export const useSetFirstLoginTime = () => {
  const { isUserLoggedIn } = useUserAuth();

  const searchParams = useSearchParams();
  const codeAvailable = searchParams.get('code');

  useEffect(() => {
    const loginEnabled = getConfig('login.enabled') || false;

    if (loginEnabled && codeAvailable && isUserLoggedIn) {
      setFirstLoginTime();
    }
  }, [isUserLoggedIn, codeAvailable]);
};
