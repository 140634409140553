import { HamburgerTheme } from '@/components/Hamburger/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: HamburgerTheme });

const Base = $({ as: 'label', slot: 'base' });
const Segment = $({ as: 'hr', slot: 'segment' });
const Toggle = $({ as: 'input', slot: 'toggle' });

export const Hamburger = Object.assign(Base, {
  Segment,
  Toggle,
});
