import { useClassName } from '@/styles/Link';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import NextLink from 'next/link';
import { isNotUndefined } from 'typesafe-utils';

export interface LinkProps extends ComponentProps<'a'> {
  type?: 'reload' | 'next-link' | 'force-reload' | 'force-next-link';
}

const LinkComponent: Component<LinkProps> = ({ children, type, href, ...props }) => {
  const className = useClassName('', props);

  const resolvedType = type || process.env.OCELOT_LINK_TYPE || 'reload';
  const isNextLink = ['next-link', 'force-next-link'].includes(resolvedType);

  if (isNextLink && isNotUndefined(href))
    return (
      <NextLink {...{ href }} {...mergeProps({ className }, props)} suppressHydrationWarning>
        {children}
      </NextLink>
    );

  return (
    <a {...{ href }} {...mergeProps({ className }, props)} suppressHydrationWarning>
      {children}
    </a>
  );
};

export const Link = LinkComponent;
