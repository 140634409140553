import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterSitesProps = ComponentProps<'ul'>;

export const FooterSitesComponent: Component<FooterSitesProps> = ({ variant, size, colors, children, ...props }) => {
  const className = componentClassName('sites', { variant, size, colors });

  return <ul {...mergeProps({ className }, props)}>{children}</ul>;
};
