import { GrowthBook, Context as GrowthBookContext } from '@growthbook/growthbook-react';
import getGrowthBookContext from './getGrowthBookContext';

const getGrowthBook = (context?: GrowthBookContext) => {
  const growthBookContext = getGrowthBookContext(context);

  const growthBook = new GrowthBook(growthBookContext || {});

  return growthBook;
};

export default getGrowthBook;
