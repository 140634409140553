import { Context as GrowthBookContext } from '@growthbook/growthbook-react';

const getGrowthBookContext = (context?: GrowthBookContext) => {
  const apiHost = process.env.GROWTHBOOK_API_HOST || 'https://cdn.growthbook.io';
  const clientKey = process.env.GROWTHBOOK_CLIENT_KEY;

  if (!clientKey) {
    return null;
  }

  return {
    apiHost,
    clientKey,
    ...context,
  };
};

export default getGrowthBookContext;
