import { TextField, TextFieldProps } from '@/components/TextField';
import { propClassName } from '@/styles/Navigation';
import { StandaloneComponent } from '@/types/component';
import { cn } from '@/utils/cn';

export interface NavigationTextFieldProps extends Omit<TextFieldProps, 'caption' | 'label'> {}

export const NavigationTextFieldComponent: StandaloneComponent<NavigationTextFieldProps> = ({ options, ...props }) => {
  const { $input, className, ...baseProps } = options ?? {};
  const { className: inputClassName, ...inputProps } = $input ?? {};

  const inputColorsClassName = propClassName('textField_input_colors', (inputProps as any).colors);
  const inputSizeClassName = propClassName('textField_input_size', (inputProps as any).size);
  const inputVariantClassName = propClassName('textField_input_variant', (inputProps as any).variant);
  const inputComponentClassName = cn(inputColorsClassName, inputSizeClassName, inputVariantClassName, inputClassName);

  const colorsClassName = propClassName('textField_colors', (baseProps as any).colors);
  const sizeClassName = propClassName('textField_size', (baseProps as any).size);
  const variantClassName = propClassName('textField_variant', (baseProps as any).variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <TextField
      options={{
        $caption: { className: 'hidden' },
        $input: {
          className: inputComponentClassName,
          ...inputProps,
        },
        $label: { className: 'hidden' },
        className: componentClassName,
        ...baseProps,
      }}
      {...props}
    />
  );
};
