/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTextField as ResolvedStandaloneTextField } from 'base/components/standalone/TextField';

export const TextField: typeof ResolvedStandaloneTextField = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTextField {...props} />
        </ErrorBoundary>
    );
});

export type TextFieldProps = PropsFromComponent<typeof TextField>;

/** @deprecated Use slot architecture instead */
export const TextFieldWith = (extras: DynamicStandaloneExtras): typeof TextField => {
    return function TextField(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextField');
        return <ResolvedStandaloneTextField {...mergeProps({ options: { theme } }, props)} />;
    }
}