import { useClassName } from '@/styles/Button';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export interface ButtonProps extends ComponentProps<'button'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'small' | 'medium' | 'large'>;
  isFullWidth?: boolean;
}

const ButtonComponent: Component<ButtonProps> = ({ children, isFullWidth, ...props }) => {
  const className = useClassName('', props, {
    defaults: { colors: 'primary', size: 'medium' },
    className: isFullWidth && 'w-full',
  });

  return <button {...mergeProps({ type: 'button', className }, props)}>{children}</button>;
};

export const Button = ButtonComponent;
