import { Icon as StandaloneIcon } from '@/components/Icon';
import { TextFieldTheme } from '@/components/TextField/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TextFieldTheme });

const Base = $({ as: 'div', slot: 'base' });
const Caption = $({ as: 'span', slot: 'caption' });
const Group = $({ as: 'div', slot: 'group' });
const Input = $({ as: 'input', slot: 'input' });
const Label = $({ as: 'label', slot: 'label' });
const Icon = GenericSlot({ as: StandaloneIcon, theme: TextFieldTheme.Icon });

export const TextField = Object.assign(Base, {
  Caption,
  Group,
  Input,
  Label,
  Icon,
});
