import { Link } from '@/components/Link';

export type Page4XXType = 404 | 410;

export const config: Record<
  Page4XXType,
  {
    description: React.ReactNode;
    headline: React.ReactNode;
    title: React.ReactNode;
  }
> = {
  404: {
    description: (
      <>
        <p>Vi kunde tyvärr inte hitta sidan du letade efter.</p>
        <p>
          <Link href="/" content="Klicka här" /> för att komma tillbaka till startsidan eller välj något i menyn ovan.
        </p>
      </>
    ),
    headline: `OJDÅ, NU BLEV DET LITE TOKIGT (404)`,
    title: `Sidan saknas (404)`,
  },
  410: {
    description: (
      <>
        <p>Vi kunde tyvärr inte hitta sidan du letade efter.</p>
        <p>
          <Link href="/" content="Klicka här" /> för att komma tillbaka till startsidan eller välj något i menyn ovan.
        </p>
      </>
    ),
    headline: 'OJDÅ, NU BLEV DET LITE TOKIGT (410)',
    title: 'Sidan saknas (410)',
  },
};
