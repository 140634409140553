import { ClassNameProps, LegacyThemeProp } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from './getPropStylesFactory';

const getKeyFactory = (key: string) => (value: string) => [key, value].filter(Boolean).join('_');

type ComponentStylesOptions = { defaults?: ClassNameProps } & LegacyThemeProp;

export const getComponentStylesFactory =
  (propClassName: ReturnType<typeof getPropStylesFactory>) =>
  (key: string, props: ClassNameProps, options?: ComponentStylesOptions) => {
    const { defaults, theme } = options || {};

    const getKey = getKeyFactory(key);

    props = {
      colors: undefined,
      size: undefined,
      variant: undefined,
      ...props,
    };

    return cn(
      Object.entries(props).map(([key, value]) => {
        return propClassName(getKey(key), value, defaults?.[key as keyof ClassNameProps], theme);
      }),
    );
  };
