import { Link, LinkProps } from '@/components/Link';
import { useClassName } from '@/styles/Button';
import { Component } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { ButtonProps } from './Button';

interface ButtonLinkProps extends ButtonProps {
  link: LinkProps;
}

const ButtonLinkComponent: Component<ButtonLinkProps> = ({ children, isFullWidth, link, ...props }) => {
  const className = useClassName('', props, {
    defaults: { colors: 'primary', size: 'medium' },
    className: isFullWidth && 'w-full',
  });

  return <Link content={children} {...link} {...mergeProps({ className }, props)} />;
};

export const ButtonLink = ButtonLinkComponent;
