import { useStable } from '@/hooks/useStable';
import { StandaloneStateProviders } from 'base/components/StateProviders';

export const MabraStandaloneStateProviders: typeof StandaloneStateProviders = ({ children, ...pageProps }) => {
  const { pageData } = pageProps ?? {};
  const { publishedUrl } = pageData?.data ?? {};
  const pageType = pageData?.type;
  const affiliate = publishedUrl?.split('/')[1] ?? '';

  const appState = useStable(pageProps?.appState, { affiliate, pageType });

  return (
    <StandaloneStateProviders {...{ appState }} {...pageProps}>
      {children}
    </StandaloneStateProviders>
  );
};
