import { Context } from '@growthbook/growthbook-react';
import { isNotEmpty, isString } from 'typesafe-utils';

type GrowthBookContext = Required<Context>;
type TrackingCallback = GrowthBookContext['trackingCallback'];
type FeatureUsageCallback = GrowthBookContext['onFeatureUsage'];

export const handleTrackingCallback: TrackingCallback = (experiment, result) => {
  addToDataLayer(experiment, result);
  trackVersionWithAdManager(experiment, result);
};

export const handleOnFeatureUsage: FeatureUsageCallback = (key, result) => {
  // Disable this for now, as we are using trackingCallback event instead
};

const addToDataLayer: TrackingCallback = (experiment, result) => {
  const allResults = [{ experiment, result }];
  const dataToPush = {
    event: 'experiment_results',
    results: allResults,
  };

  window.dataLayer ??= [];
  window.dataLayer.push(dataToPush);
};

const trackVersionWithAdManager: TrackingCallback = (experiment, result) => {
  const isResultKeyValid = isString(result.key) && Number.isInteger(Number(result.key));
  const isExperimentKeyValid = isString(experiment.key) && isNotEmpty(experiment.key);

  if (!isResultKeyValid || !isExperimentKeyValid) {
    return;
  } 

  const trackingKeys = [result.key, experiment.key];

  window.gbVersionAds ??= [];
  window.gbVersionAds.push(trackingKeys);

  const event = new CustomEvent('gb:tracking-version-callback', { detail: trackingKeys });
  window.dispatchEvent(event);
};

const growthBookEventHandlers = {
  handleOnFeatureUsage,
  handleTrackingCallback,
};

export default growthBookEventHandlers;
