import { isDev } from '@/utils/isDev';
import { removeTrailingSlash } from '@/utils/removeTrailingSlash';

export function getDomain(trailingSlash = true) {
  let domain: string = process.env.OCELOT_DOMAIN || '';

  if (!domain && isDev) {
    domain = `http://localhost:${process.env.PORT}`;
  }

  if (!domain) throw new Error('Missing env: OCELOT_DOMAIN');
  return removeTrailingSlash(domain) + (trailingSlash ? '/' : '');
}
