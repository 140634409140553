import {
  ElementKey,
  InstanceofProp,
  ProxyProp,
  StandaloneComponent,
  StandaloneComponentProps,
} from '@/types/component';
import { isFunction } from '@/types/is';
import { PartPartial } from '@/types/utils';
import { isIntrinsicElement } from '@/utils/isIntrinsicElement';
import { logger } from '@/utils/logger';
import { arrayCustomizer, Merge, MergeCustomizer, withCustomizers } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { InstanceofSlotComponent, InstanceofStandaloneComponent, withSlotInstanceof } from '@/utils/withInstanceofProp';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';
import { mergeWith, omitBy, pickBy, upperFirst } from 'lodash-es';
import React, { ComponentProps, ReactElement } from 'react';
import { TVReturnType, VariantProps } from 'tailwind-variants';
import { isNotUndefined, isUndefined } from 'typesafe-utils';

// @ts-expect-error: shortcut for inferred generics
export type AnyTheme = TVReturnType;

type AnyContext = React.Context<any>;

type ContextProp<Context extends AnyContext> = { context?: Context };

type ChildrenProp = { children?: React.ReactNode };

type ThemeProp<Theme extends AnyTheme> = { theme?: Theme };

type Slots<Theme extends AnyTheme> = keyof Theme['slots'] | keyof Theme['extend']['slots'];

type HTMLElementFromKey<Element extends ElementKey> = Element extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[Element]
  : never;

type InferredOptions<Element extends ElementKey, Theme extends AnyTheme> =
  Element extends InstanceofProp<typeof InstanceofStandaloneComponent>
    ? Element extends StandaloneComponent<infer Props>
      ? Props extends StandaloneComponentProps<infer Component, infer Extras>
        ? Omit<Props, 'options'> & StandaloneComponentProps<Component, Extras, VariantProps<Theme>>
        : never
      : never
    : React.ComponentPropsWithoutRef<Element> & VariantProps<Theme>;

type GenericFunctionComponentProps<
  Element extends ElementKey,
  Theme extends AnyTheme,
  Context extends AnyContext,
> = InferredOptions<Element, Theme> & ProxyProp<Element> & ThemeProp<Theme> & ContextProp<Context> & ChildrenProp;

export type GenericSlotFunction<Element extends ElementKey, Theme extends AnyTheme, Context extends AnyContext> = ((
  props: GenericFunctionComponentProps<Element, Theme, Context> & React.RefAttributes<HTMLElementFromKey<Element>>,
) => React.ReactNode) & {
  displayName?: React.FunctionComponent['displayName'];
} & ProxyProp<Element>;

export type GenericSlotRender<Element extends ElementKey> = (props: {
  element: ReactElement<ComponentProps<Element>, Element>;
  props: ComponentProps<Element>;
  children: React.ReactNode;
  ref?: React.ForwardedRef<React.ComponentRef<Element>>;
}) => JSX.Element;

export type GenericSlotProps<Element extends ElementKey, Theme extends AnyTheme, Context extends AnyContext> = {
  theme: Theme;
  slot?: Slots<Theme>;
  render?: GenericSlotRender<Element>;
  debug?: boolean;
} & Required<ProxyProp<Element>> &
  ContextProp<Context>;

export type GenericSlot = <
  Element extends ElementKey,
  Theme extends AnyTheme,
  Context extends AnyContext,
  InferredElement extends ElementKey = Element extends ProxyProp<infer Proxy> ? Proxy : Element,
>(
  props: GenericSlotProps<Element, Theme, Context>,
) => GenericSlotFunction<InferredElement, Theme, Context>;

// TODO: fix type mismatch for `as` when using extended slot
// @ts-expect-error: type mismatch for `as` when using extended slot
export const GenericSlot: GenericSlot = ({ debug, render, slot, ...generic }) => {
  const defaultContext = React.createContext({});

  // eslint-disable-next-line react/display-name
  const Slot: ReturnType<GenericSlot> = React.forwardRef(
    ({ as, children, theme, options: unsafeOptions, ...unsafeProps }, ref) => {
      const props = omitBy(unsafeProps, isUndefined);
      const options = omitBy(unsafeOptions, isUndefined);

      const Element = (as || generic.as || 'div') as ElementKey;
      const isStringElement = typeof Element === 'string';
      const isCustomElement = isStringElement && !isIntrinsicElement(Element);
      const resolvedClass = isCustomElement ? 'class' : 'className';

      // @ts-expect-error: `$$instanceof` is a custom property
      const isExtendedSlot = Element.$$instanceof === InstanceofSlotComponent;

      // @ts-expect-error: `$$instanceof` is a custom property
      const isStandaloneComponent = Element.$$instanceof === InstanceofStandaloneComponent;

      const ImplicitContext = (generic.context || defaultContext) as React.Context<any>;
      const implicitContextValue = React.useContext(ImplicitContext);

      const ExplicitContext = (props.context || defaultContext) as React.Context<any>;
      const explicitContextValue = React.useContext(ExplicitContext);

      const { Provider } = ImplicitContext;

      const resolvedTheme = [implicitContextValue.theme, theme, generic.theme].find(isFunction);

      const variantKeys: string[] = resolvedTheme?.variantKeys ?? [];
      const isVariant = (value: any, key: string) => variantKeys.includes(key);
      const isContext = (value: any, key: string) =>
        isNotUndefined(value) && (key.startsWith('$') || ['context', 'theme', ...variantKeys].includes(key));

      const slotKey = `$${String(slot)}`;

      const relatedProps = {
        ...implicitContextValue,
        ...implicitContextValue?.[slotKey],
        ...explicitContextValue,
        ...explicitContextValue?.[slotKey],
        ...props,
        ...options,
        ...options?.[slotKey],
      };

      const resolvedRef = ref || relatedProps.ref;
      const resolvedVariants = pickBy(relatedProps, isVariant);

      const resolvedStyles = !(isExtendedSlot || isStandaloneComponent)
        ? ((resolvedTheme()?.[slot || 'base'] || resolvedTheme)?.(resolvedVariants) as string)
        : undefined;

      const resolvedContext = pickBy({ theme, ...explicitContextValue, ...props, ...options }, isContext);
      const withContextProvider = Object.keys(resolvedContext).length > 0 && !(isExtendedSlot || isStandaloneComponent);

      const resolvedProps = omitBy(
        { ...implicitContextValue?.[slotKey], ...props, ...options, ...options?.[slotKey] },
        isContext,
      );

      const resolvedElementProps: ComponentProps<typeof Element> = {};

      switch (true) {
        case isExtendedSlot:
          Object.assign(resolvedElementProps, {
            theme: resolvedTheme,
            context: generic.context,
            ...resolvedVariants,
            ...resolvedProps,
          });
          break;
        case isStandaloneComponent:
          Object.assign(resolvedElementProps, {
            theme: resolvedTheme,
            context: generic.context,
            options,
            ...props,
          });
          break;
        default:
          Object.assign(resolvedElementProps, merge({ [resolvedClass]: resolvedStyles }, resolvedProps));
          break;
      }

      let element = (
        <Element {...withSafeInnerHTML(children)} {...resolvedElementProps} ref={resolvedRef}>
          {withNonHTMLChildren(children)}
        </Element>
      );

      if (render) {
        element = render({ element, props: resolvedElementProps, children, ref: resolvedRef });
      }

      if (withContextProvider) {
        element = <Provider value={resolvedContext}>{element}</Provider>;
      }

      if (debug) {
        logger.debug({
          Element,
          GenericSlot: { render, slot, generic },
          Slot: { as, children, theme, options, props, ref },
          context: {
            ImplicitContext,
            implicitContextValue,
            ExplicitContext,
            explicitContextValue,
          },
          resolved: {
            resolvedTheme,
            resolvedVariants,
            resolvedContext,
            resolvedStyles,
            resolvedProps,
            resolvedElementProps,
          },
          is: {
            isStringElement,
            isCustomElement,
            isExtendedSlot,
            isStandaloneComponent,
          },
        });
      }

      return element;
    },
  );

  if (!Slot.displayName) {
    Slot.displayName = upperFirst(slot?.toString()) || 'Base';
  }

  withSlotInstanceof(Slot);

  return Slot;
};

export type GenericSlotFactoryProps<Theme extends AnyTheme, FactoryContext extends AnyContext> = {
  theme: Theme;
  context?: FactoryContext;
  debug?: boolean;
};

type Defined<A, B> = A extends undefined ? B : A;

export const GenericSlotFactory = <FactoryTheme extends AnyTheme, FactoryContext extends AnyContext>(
  factoryProps: GenericSlotFactoryProps<FactoryTheme, FactoryContext>,
) => {
  const context = factoryProps?.context ?? React.createContext({});

  const slot = <Element extends ElementKey, Theme extends AnyTheme = undefined>(
    props: PartPartial<GenericSlotProps<Element, Defined<Theme, FactoryTheme>, typeof context>, 'theme'>,
  ) =>
    GenericSlot<Element, Defined<Theme, FactoryTheme>, typeof context>({
      ...factoryProps,
      context,
      ...props,
    });

  return slot;
};

const classMergeCustomizer: MergeCustomizer = (a, b, key) => {
  if (key === 'className' || key === 'class') {
    return tw.merge(a, b);
  }
};

const merge: Merge = (...props) => mergeWith({}, ...props, withCustomizers(classMergeCustomizer, arrayCustomizer));
