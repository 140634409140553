import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
  base: `text-black group-data-[status=error]:text-error-500`,
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    label: `text-gray-900 text-headline-xs`,
    input: `bg-white shadow-[0_0_0_0] shadow-success-200 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 group-data-[status=error]:bg-white group-data-[status=error]:shadow-[0_0_0_0] group-data-[status=error]:outline-none group-data-[status=error]:ring-1 group-data-[status=error]:ring-inset group-data-[status=error]:ring-error-500 group-data-[status=error]:placeholder:text-gray-700 group-data-[status=error]:hover:ring-error-500 hover:not-focus:shadow-[0_0_0_0] hover:not-focus:shadow-success-200 hover:not-focus:ring-success-500 focus:shadow-[0_0_10px_0] focus:shadow-success-200 focus:ring-success-500`,
    caption: `text-gray-900 text-headline-xs`,
  },
  variants: {
    variant: {
      secondary: {
        label: `group-focus-within:text-headline-2xs group-data-[filled]:text-headline-2xs`,
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
