import { SoMe, SoMeProps } from '@/components/SoMe';
import c from '@/styles/Navigation';
import { StandaloneComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface NavigationSoMeProps extends SoMeProps {}

export const NavigationSoMeComponent: StandaloneComponent<NavigationSoMeProps> = ({ options, ...props }) => {
  const { colors, size, variant, className, ...baseProps } = options ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('soMe_colors', colors, 'filled');
  const sizeClassName = getPropStyles('soMe_size', size);
  const variantClassName = getPropStyles('soMe_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return <SoMe options={{ colors, size, variant, className: componentClassName, ...baseProps }} {...props} />;
};
