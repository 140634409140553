import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type FooterSoMeGroupProps = ComponentProps<'aside'>;

export const FooterSoMeGroupComponent: Component<FooterSoMeGroupProps> = ({
  children,
  colors,
  size,
  variant,
  ...props
}) => {
  const className = componentClassName('soMe_group', { variant, colors, size });

  return <aside {...mergeProps({ className }, props)}>{children}</aside>;
};
