import { prose, typography } from 'modules/tailwind/theme.config';
import { extendTailwindMerge, fromTheme, twJoin } from 'tailwind-merge';
import { createTV } from 'tailwind-variants';
import { isString } from 'typesafe-utils';

type ExtraClassGroups = 'prose' | 'typography' | 'wings';
type ExtraThemeGroups = never;

// Extracted from tailwind-merge types
type ClassNameValue = ClassNameArray | string | null | undefined | 0 | 0n | false;
type ClassNameArray = ClassNameValue[];

const isCommonProse = (classPart: string) => {
  return /(coverbox|factbox)/.test(classPart);
};

const isCommonTypograph = (classPart: string) => {
  return /(headline|body|preamble|blockquote|input|quote|content)-?\d?(xs|sm|md|lg|xl)?/.test(classPart);
};

const twMergeConfig = {
  extend: {
    classGroups: {
      prose: [{ prose: [isCommonProse, ...prose] }],
      typography: [{ text: [isCommonTypograph, ...typography] }],
      wings: [{ wings: [fromTheme('colors')] }],
    },
  },
};

const customTailwindVariants = createTV({ twMergeConfig });

type TVConfig = Parameters<typeof customTailwindVariants>[0];

const classNameArrayToString = (array: ClassNameArray): string => {
  return array
    .reduce<string[]>((result, value) => {
      if (Array.isArray(value)) {
        result.push(...classNameArrayToString(value));
      }

      if (isString(value)) {
        result.push(value);
      }

      return result;
    }, [] as string[])
    .join(' ');
};

const fixClassNameObjectMerging = (config: TVConfig): void => {
  if (Array.isArray(config.base)) {
    config.base = classNameArrayToString(config.base);
  }

  if (config.slots) {
    for (const slot in config.slots) {
      if (Array.isArray(config.slots[slot])) {
        config.slots[slot] = classNameArrayToString(config.slots[slot]);
      }
    }
  }

  if (config.variants) {
    for (const variant in config.variants) {
      for (const option in config.variants[variant]) {
        if (Array.isArray(config.variants[variant][option])) {
          config.variants[variant][option] = classNameArrayToString(config.variants[variant][option]);
        }
      }
    }
  }
};

export const twTheme: typeof customTailwindVariants = (config) => {
  fixClassNameObjectMerging(config as TVConfig);

  return customTailwindVariants(config);
};

export const twMerge = extendTailwindMerge<ExtraClassGroups, ExtraThemeGroups>(twMergeConfig);

export const tw = {
  theme: twTheme,
  merge: twMerge,
  join: twJoin,
};
