import { AnyString } from '@/types/utils';

type ExtractedThemes<R extends Record<string, any>> = {
  [K in keyof R as R[K] extends Record<string, any> ? K : never]: R[K];
};

export const getComponentThemeFactory =
  <T extends Record<string, any>>(styles: T) =>
  (key: keyof ExtractedThemes<T> | AnyString) => {
    return styles[key as keyof ExtractedThemes<T>];
  };
