/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneMain as ResolvedStandaloneMain } from 'base/components/standalone/Main';

export const Main: typeof ResolvedStandaloneMain = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneMain {...props} />
        </ErrorBoundary>
    );
});

export type MainProps = PropsFromComponent<typeof Main>;

/** @deprecated Use slot architecture instead */
export const MainWith = (extras: DynamicStandaloneExtras): typeof Main => {
    return function Main(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Main');
        return <ResolvedStandaloneMain {...mergeProps({ options: { theme } }, props)} />;
    }
}